import React, { useCallback, useState } from 'react';
import { Element } from 'react-scroll';
import { useFormik } from 'formik';
import styled from 'styled-components';

import Alert from '../components/alert';
import Button from '../components/form/button';
import GridForm from '../components/form/grid-form';
import Input from '../components/form/input';
import Textarea from '../components/form/textarea';
import GridCell, { FullCell, HalfCell } from '../components/grid/grid-cell';
import LightHeader from '../components/header/light-header';
import PageTitle from '../components/page/page-title';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';
import { formikValidation, grid as gridUtil, scroll } from '../utils';

export const SubmitCell = styled(GridCell)`
  width: ${gridUtil.cellSize(12)};
  display: flex;
  justify-content: flex-end;
`;

export default function ContactPage() {
  const pageTitle = 'Contact';

  const [alert, setAlert] = useState({
    type: null,
    visible: false,
    message: '',
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { name: '', email: '', subject: '', message: '' },
    validate: function handleValidation(values) {
      const rules = {
        name: 'required',
        email: 'required|email',
        subject: 'required',
        message: 'required',
      };

      return formikValidation(values, rules);
    },
    onSubmit: useCallback(
      (values, { resetForm, setSubmitting }) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'contact', ...values }),
        })
          .then(() => {
            setAlert({
              type: 'success',
              visible: true,
              message: "Message sent. I'll respond within 24 hours.",
            });

            resetForm();
            setSubmitting(false);
          })
          .catch(() => {
            setAlert({
              type: 'error',
              visible: true,
              message: 'There is a server error. Please try again later.',
            });

            resetForm();
            setSubmitting(false);
          });
      },
      [setAlert]
    ),
  });

  const handleCustomSubmit = useCallback(
    (event) => {
      scroll.scrollTo('contact-form');
      formik.handleSubmit(event);
    },
    [formik]
  );

  const hasValidationError = !formik.isValid && formik.submitCount > 0;

  return (
    <Element name="contact-form">
      <PageWrapper>
        <SEO
          title={pageTitle}
          jsonLd={{
            '@type': 'ContactPage',
          }}
        />

        <LightHeader />

        <PageTitle smallMargin>{pageTitle}</PageTitle>

        <GridForm
          onSubmit={handleCustomSubmit}
          noValidate
          disabled={formik.isSubmitting}
          name="contact"
          method="POST"
          data-netlify="true"
        >
          <Alert
            visible={hasValidationError ? true : alert.visible}
            type={hasValidationError ? 'error' : alert.type}
          >
            {hasValidationError
              ? 'Please fix the errors and submit again.'
              : alert.message}
          </Alert>

          <HalfCell>
            <Input
              type="text"
              name="name"
              label="Name"
              form={formik}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </HalfCell>

          <HalfCell>
            <Input
              type="email"
              name="email"
              label="Email"
              form={formik}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </HalfCell>

          <FullCell>
            <Input
              type="text"
              name="subject"
              label="Subject"
              form={formik}
              onChange={formik.handleChange}
              value={formik.values.subject}
            />
          </FullCell>

          <FullCell>
            <Textarea
              name="message"
              label="Message"
              form={formik}
              onChange={formik.handleChange}
              value={formik.values.message}
            />
          </FullCell>

          <SubmitCell>
            <Button type="submit" backgroundColor="blue">
              Send
            </Button>
          </SubmitCell>
        </GridForm>
      </PageWrapper>
    </Element>
  );
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
