import React from 'react';
import styled from 'styled-components';

import FieldError from './field-error';
import FieldLabel from './field-label';
import { InputElement } from './input';

const TextareaInput = InputElement.withComponent('textarea');

const StyledTextarea = styled(TextareaInput)`
  resize: none;
  min-height: 200px;
`;

export default function Textarea({ name, label, form, ...rest }) {
  return (
    <>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      <StyledTextarea id={name} name={name} {...rest} />
      <FieldError error={form.errors[name]} htmlFor={name} />
    </>
  );
}
